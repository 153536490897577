<script>
import "chart.js/auto";
import { Pie } from "vue-chartjs";

export default {
  name: "Pie",
  extends: Pie,
  comments: {
    Pie,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    styles: {
      type: Object,
      default: null,
    },
  },
};
</script>
